import "styles/pages/page-center-monuments.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TextWithSymbol from "components/TextWithSymbol";
import Breadcrumbs from "components/Breadcrumbs";

const CenterMonumentsPage = ({ pageContext }) => {
	const pageCMonuments = pageContext.pageContent;
	return (
		<Layout headerColor="#FFCE2E">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={
					require("assets/images/centermonuments-bg-main.jpg").default
				}
			/>
			<Breadcrumbs
				prevLocationLink="/zabytki"
				prevLocationLabel="Zabytki"
				currentLocationLabel="Zabytki Centrum"
			/>
			<section className="bangok">
				<div className="container">
					<h2 className="section-title">
						{pageCMonuments.section1Title}
					</h2>
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageCMonuments.section1Subtitle}
								desc={pageCMonuments.section1Text}
								addres={pageCMonuments.section1Address}
								hours={pageCMonuments.section1Hours}
								phone={pageCMonuments.section1Phone}
								arrive={pageCMonuments.section1Transport}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageCMonuments.section1Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="muzeum-pipit">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageCMonuments.section2Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageCMonuments.section2Title}
								desc={pageCMonuments.section2Text}
								addres={pageCMonuments.section2Address}
								hours={pageCMonuments.section2Hours}
								phone={pageCMonuments.section2Phone}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="market">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageCMonuments.section3Title}
								desc={pageCMonuments.section3Text}
								addres={pageCMonuments.section3Address}
								hours={pageCMonuments.section3Hours}
								phone={pageCMonuments.section3Phone}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageCMonuments.section3Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="street-food">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-5 text-center">
							<img
								src={pageCMonuments.section4Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-2 ">
							<TextWithSymbol
								title={pageCMonuments.section4Title}
								desc={pageCMonuments.section4Text}
								addres={pageCMonuments.section4Address}
								hours={pageCMonuments.section4Hours}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="wycieczki-kulinarne">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageCMonuments.section5Title}
								desc={pageCMonuments.section5Text}
								addres={pageCMonuments.section5Address}
								hours={pageCMonuments.section5Hours}
							/>
						</div>
						<div className="col-xl-4 offset-xl-2 text-center">
							<img
								src={pageCMonuments.section5Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="ayutthaya">
				<div className="container">
					<h2 className="section-title">
						{pageCMonuments.section6Title}
					</h2>
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-5 text-center">
							<img
								src={pageCMonuments.section6Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-2">
							<TextWithSymbol
								title={pageCMonuments.section6Subtitle}
								desc={pageCMonuments.section6Text}
								addres={pageCMonuments.section6Address}
								hours={pageCMonuments.section6Hours}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="phra-mahathat">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageCMonuments.section7Title}
								desc={pageCMonuments.section7Text}
								addres={pageCMonuments.section7Address}
								hours={pageCMonuments.section7Hours}
								phone={pageCMonuments.section7Phone}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageCMonuments.section7Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="muzeum-ulicy">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageCMonuments.section8Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageCMonuments.section8Title}
								desc={pageCMonuments.section8Text}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="bang-sai">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageCMonuments.section9Title}
								desc={pageCMonuments.section9Text}
								hours={pageCMonuments.section9Hours}
								info={pageCMonuments.section9ExtraInfo}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageCMonuments.section9Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="kanchanaburi">
				<div className="container">
					<h2 className="section-title">
						{pageCMonuments.section10Title}
					</h2>
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-5 text-center">
							<img
								src={pageCMonuments.section10Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
						<div className="col-xl-5 offset-xl-2">
							<TextWithSymbol
								title={pageCMonuments.section10Subtitle}
								desc={pageCMonuments.section10Text}
								addres={pageCMonuments.section10Address}
								hours={pageCMonuments.section10Hours}
								phone={pageCMonuments.section10Phone}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="tham-kra">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageCMonuments.section11Title}
								desc={pageCMonuments.section11Text}
								hours={pageCMonuments.section11Hours}
								arrive={pageCMonuments.section11Transport}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageCMonuments.section11Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="drzewo-deszczowe">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageCMonuments.section12Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageCMonuments.section12Title}
								desc={pageCMonuments.section12Text}
								addres={pageCMonuments.section12Address}
								phone={pageCMonuments.section12Phone}
								arrive={pageCMonuments.section12Transport}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="paprak">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageCMonuments.section13Title}
								desc={pageCMonuments.section13Text}
								addres={pageCMonuments.section13Address}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageCMonuments.section13Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="rzeka">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageCMonuments.section14Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageCMonuments.section14Title}
								desc={pageCMonuments.section14Text}
								arrive={pageCMonuments.section14Transport}
							/>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default CenterMonumentsPage;
